:root {
  /* Colour definitions */
  --dark-colour: #171717;
  --light-colour: #ffffff;
  --hover-background-rgb: 69, 69, 69;
  --light-blue-grey-colour: #f1f0fd;
  --light-blue-colour: #b9c0ff;
  --highlight-blue-colour: #a7b7e9;
  --dark-blue-colour: #120067;
  --highlight-purple-colour: #250a40;
  --dark-grey-colour: #494949;
  --light-grey-colour: #d4d4d4;
  --dark-grey-colour: #444444;
  --light-purple-colour: #c89ed6;
  --dark-purple-colour: #6a0dad;
}

body {
  margin: 0;
  font-family: "RobotoDraft", "Roboto", sans-serif;
  font-size: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
}

h1 {
  font-size: 2.5em;
}

h2 {
  font-size: 1.875em;
}

p {
  font-size: 1.25em;
}

a {
  font-size: 1.25em;
}

button {
  font-size: 1.25em;
}

.section-content {
  margin-left: 10%;
  margin-right: 10%;
  padding-top: 70px;
  padding-bottom: 70px;
}

.section-border-bottom {
  border-bottom: solid 1px var(--primary-colour);
}

.dark {
  --background-colour: var(--dark-colour);
  --primary-colour: var(--light-colour);
  --secondary-background-colour: var(--dark-grey-colour);
  --tertiary-background-colour: var(--dark-blue-colour);
  --highlight-colour: var(--highlight-purple-colour);
  --link-colour: var(--light-blue-colour);
  --contrast-highlight-colour: var(--light-purple-colour);
}

.light {
  --background-colour: var(--light-colour);
  --primary-colour: var(--dark-colour);
  --secondary-background-colour: var(--light-blue-grey-colour);
  --tertiary-background-colour: var(--light-blue-colour);
  --highlight-colour: var(--highlight-blue-colour);
  --link-colour: var(--dark-blue-colour);
  --contrast-highlight-colour: var(--dark-purple-colour);
}

@media (prefers-color-scheme: dark) {
  .auto {
    --background-colour: var(--dark-colour);
    --primary-colour: var(--light-colour);
    --secondary-background-colour: var(--dark-grey-colour);
    --tertiary-background-colour: var(--dark-blue-colour);
    --highlight-colour: var(--highlight-purple-colour);
    --link-colour: var(--light-blue-colour);
    --contrast-highlight-colour: var(--light-purple-colour);
  }
}

@media (prefers-color-scheme: light) {
  .auto {
    --background-colour: var(--light-colour);
    --primary-colour: var(--dark-colour);
    --secondary-background-colour: var(--light-blue-grey-colour);
    --tertiary-background-colour: var(--light-blue-colour);
    --highlight-colour: var(--highlight-blue-colour);
    --link-colour: var(--dark-blue-colour);
    --contrast-highlight-colour: var(--dark-purple-colour);
  }
}

.application {
  background-color: var(--background-colour);
  color: var(--primary-colour);
}

.icon-fill {
  fill: var(--primary-colour);
}

.icon-background-fill {
  fill: var(--background-colour);
}

.icon-transparent-background-fill {
  fill: transparent;
}

.icon-stroke {
  stroke: var(--primary-colour);
}
.icon-padding {
  padding: 5px;
}

@keyframes from-right-slide-in-animation {
  from {
    right: -300px;
    opacity: 0;
  }
  to {
    right: 0;
    opacity: 1;
  }
}

.from-right-slide-in-animation {
  animation: from-right-slide-in-animation 0.3s ease;
}

@keyframes to-right-slide-out-animation {
  from {
    right: 0;
    opacity: 1;
  }
  to {
    right: -300px;
    opacity: 0;
  }
}

.to-right-slide-out-animation {
  animation: to-right-slide-out-animation 0.3s ease;
}

.new-page-link {
  color: var(--link-colour);
  text-decoration: none;
  font-weight: bold;
  font-size: 1.5em;
}

.new-page-link:hover {
  text-decoration: underline;
}

.inline-start-margin {
  margin-inline-start: 5px;
}

.inline-link {
  font-size: 1em;
}
