.sorting-option-container {
  display: flex;
  align-items: center;
}

.card-container {
  display: flex;
  flex-flow: wrap;
}

.centered-flex-column {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.space-justified-card {
  justify-content: space-around;
}

.project-card {
  display: flex;
  flex-direction: column;
  margin: 1em;
  padding: 2em;
  border-radius: 7.5px;
  width: 100%;
  background-color: var(--tertiary-background-colour);
  text-decoration: none;
  color: var(--text-colour);
  font-size: 1em;
  border-bottom-style: solid;
  border-bottom-color: var(--link-colour);
  border-bottom-width: 7.5px;
  .project-card-name {
    color: var(--link-colour);
    font-size: 1.5em;
  }
  .project-card-details {
    visibility: visible;
    display: block;
    margin-top: 0;
  }

  .project-card-open-message {
    visibility: hidden;
    display: flex;
    align-items: center;
    p {
      margin: 0;
    }
  }
}

.project-card:hover {
  background-color: var(--link-colour);
  color: var(--background-colour);
  .project-card-name {
    color: var(--tertiary-background-colour);
  }
  .project-card-details {
    visibility: hidden;
  }

  .project-card-open-message {
    visibility: visible;
  }
}

.project-card:focus {
  background-color: var(--link-colour);
  color: var(--background-colour);
  .project-card-name {
    color: var(--tertiary-background-colour);
  }
  .project-card-details {
    visibility: hidden;
  }

  .project-card-open-message {
    visibility: visible;
  }
}

.language-card {
  margin: 1em;
  padding: 1em;
  border-radius: 20px;
  width: 100%;
  background-color: var(--secondary-background-colour);
}

.language-title {
  margin: 0;
  padding-left: 10px;
}

.sorting-inactive {
  border-radius: 100%;
  width: 24px;
  height: 24px;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: solid 2px var(--link-colour);
}

.sorting-inactive:hover {
  cursor: pointer;
  background-color: var(--tertiary-background-colour);
}

.sorting-active {
  border-radius: 100%;
  width: 24px;
  height: 24px;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--tertiary-background-colour);
  border: none;
}

.sorting-active:hover {
  cursor: pointer;
  border: solid 2px var(--link-colour);
}

@media only screen and (min-width: 855px) {
  .project-card {
    min-width: 300px;
    max-width: 300px;
  }
}
