.check-option-container {
  display: flex;
  align-items: center;
}

.check-inactive {
  border-radius: 100%;
  width: 24px;
  height: 24px;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: solid 2px var(--link-colour);
}

.check-inactive:hover {
  cursor: pointer;
  background-color: var(--tertiary-background-colour);
}

.check-active {
  border-radius: 100%;
  width: 24px;
  height: 24px;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--tertiary-background-colour);
  border: none;
}

.check-active:hover {
  cursor: pointer;
  border: solid 2px var(--link-colour);
}
