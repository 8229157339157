.about_me_block {
  display: flex;
  align-items: center;
}

.display-image {
  max-width: 320px;
  max-height: 180px;
  border-radius: 100%;
  width: auto;
  height: auto;
  margin: 3%;
  border: 5px solid var(--light-colour, #ffffff);
}

.link-icon {
  fill: var(--link-colour);
}

@media only screen and (max-width: 450px) {
  .display-image {
    align-self: center;
  }

  .about_me_block {
    flex-direction: column;
    align-items: flex-start;
  }
}
