.skills-container {
  display: flex;
  flex-flow: wrap;
  justify-content: space-between;
}

.skill-links {
  padding-inline-end: 2em;
}

.cloud-map-link {
  padding-bottom: 2em;
  padding-inline-end: 2em;
}

.skills-progress-bar-container {
  display: flex;
  flex-direction: column;
}

.multi-progress-bar-container {
  display: flex;
  align-items: center;
  height: 0.4em;
}

.progress-bar-height {
  height: 0.35em;
}

.full-progress-bar-border-rounding {
  border-radius: 50px;
}

.left-progress-bar-border-rounding {
  border-radius: 50px 0px 0px 50px;
}

.right-progress-bar-border-rounding {
  border-radius: 0px 50px 50px 0px;
}

.used-bar {
  background-color: var(--primary-colour);
}

.competent-bar {
  background: linear-gradient(
      to right,
      var(--primary-colour),
      var(--primary-colour) 0%,
      var(--link-colour) 10%,
      var(--link-colour) 100%,
      var(--link-colour)
    )
    0/33vw;
}

.proficient-bar {
  background: linear-gradient(
      to right,
      var(--link-colour),
      var(--link-colour) 0%,
      var(--contrast-highlight-colour) 10%,
      var(--contrast-highlight-colour) 100%,
      var(--contrast-highlight-colour)
    )
    0/33vw;
}

.skill-name-and-progress {
  display: flex;
  align-items: center;
  margin-bottom: 1em;
}

.progress-bar-cell {
  width: 100%;
  margin-inline-start: 2em;
}

.skill-name-cell {
  min-width: 8em;
  max-width: 8em;
  margin-inline-end: 2em;
}

.skills-keys-definition {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.skill-key {
  display: flex;
  align-items: center;
}

.circle {
  border-radius: 50%;
  width: 1em;
  height: 1em;
  margin: 10px;
}

.key-colour-1 {
  background-color: var(--primary-colour);
}

.key-colour-2 {
  background-color: var(--link-colour);
}

.key-colour-3 {
  background-color: var(--contrast-highlight-colour);
}
