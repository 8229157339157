.logo-text {
  text-align: end;
  width: 3.5em;
  position: absolute;
  padding-top: 1.5em;
  font-size: 4em;
}

.logo-ring {
  border-radius: 50%;
  width: 10em;
  height: 15em;
  border: solid 1em var(--primary-colour);
  animation: rotate 12s linear infinite;
}

@keyframes rotate {
  from {
    transform: rotateX(0deg) rotateY(0deg) rotateZ(0deg);
  }
  20% {
    transform: rotateX(180deg) rotateY(60deg) rotateZ(60deg);
  }
  40% {
    transform: rotateX(360deg) rotateY(120deg) rotateZ(120deg);
  }
  60% {
    transform: rotateX(180deg) rotateY(180deg) rotateZ(180deg);
  }
  80% {
    transform: rotateX(270deg) rotateY(240deg) rotateZ(240deg);
  }
  to {
    transform: rotateX(360deg) rotateY(360deg) rotateZ(360deg);
  }
}
