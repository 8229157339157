.navbar {
  z-index: 1;
  position: fixed;
  width: 100%;
  top: 0;
  background-color: var(--background-colour);
  border-bottom: solid 1px var(--primary-colour);
}

.logo-and-pages {
  display: flex;
  align-items: center;
  margin-inline-start: 2em;
}

.nav-list {
  display: flex;
  list-style: none;
  justify-content: space-evenly;
  align-items: center;
  text-transform: uppercase;
  padding-inline-start: 0;
}

.nav-link {
  text-decoration: none;
  color: var(--primary-colour);
  padding-left: 2em;
}

.nav-link:hover {
  text-decoration: underline;
}

.big-screen {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.small-screen {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.small-nav-logo-container {
  margin-left: 1em;
  padding-left: 20px;
}

.in-navbar-button {
  background-color: var(--background-colour);
  border: none;
  padding-right: 20px;
  margin: 1em;
}

.in-navbar-button:hover {
  cursor: pointer;
}

.in-navbar-settings-container {
  padding-left: 20px;
  padding-right: 20px;
}

.in-navbar-settings {
  padding-top: 20px;
}

.vertical-nav-container {
  z-index: 3;
  background-color: var(--background-colour);
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  top: 0;
  flex-direction: column;
}

.vertical-nav-close-container {
  border-bottom: solid 1px var(--primary-colour);
  display: flex;
  justify-content: flex-end;
}

.vertical-nav-list {
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  list-style: none;
}

.vertical-nav-list li {
  width: 100%;
  padding-left: 20px;
  padding-top: 10px;
  text-transform: uppercase;
}

.setting-name {
  margin-top: 0;
}

.settings-title-style {
  text-transform: uppercase;
  font-weight: bold;
}

.settings-title-border {
  border-bottom: solid 1px var(--primary-colour);
}

@media only screen and (min-width: 791px) {
  .small-screen {
    display: none;
  }

  .nav-link {
    font-size: 1em;
  }

  .settings-title-style {
    font-size: 1em;
  }
}

@media only screen and (max-width: 790px) {
  .big-screen {
    display: none;
  }
}
